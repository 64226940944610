export default function ExpProfissional({ exp }) {
  const AdicionaExperiencia = ({xp}) => {
    return (
      <article className="xp">
        <h5>{xp.empresa}</h5>
        {!!xp.date && <p className="date">{xp.date}</p>}
        {!!xp.descricao && <div dangerouslySetInnerHTML={{ __html: xp.descricao }} />}
      </article>
    );
  };
  return (
    <section className="section">
      <h4>Experiência Profissional</h4>
      {!!exp.length && exp.map((item, key) => !item.hide && <AdicionaExperiencia key={key} xp={item} />)}
    </section>
  );
}
