import {FaRegFilePdf} from 'react-icons/fa';
export default function Cursos({cursos}) {
  const Curso = ({curso}) => {
    
    return (
      <p>
        {curso.titulo}
        {!!curso.plataforma && ` - ${curso.plataforma}`}
        {!!curso.duracao && <span className="date"> {curso.duracao} </span>}
        {!!curso.certificado && <a href={`/assets/${curso.certificado}`}><FaRegFilePdf/></a>}
      </p>
    )
  }
  return (
    <section className="section" id="cursos">
      <h4>Cursos e certificados</h4>
      {cursos.map((curso, key) => <Curso key={key} curso={curso}/>)}
    </section>
  )
}
