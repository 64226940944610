export default function Habilidades({ habilidades }) {
  const MostraHabilidades = ({ habilidades }) => {
    let habLista = "";
    habilidades.forEach(
      (item, index) =>
        (habLista += item + (habilidades.length > index + 1 ? ", " : ""))
    );

    return <p className="text">{habLista}</p>;
  };
  return (
    <article className="section">
      <h4>Habilidades</h4>
      {!!habilidades.length && <MostraHabilidades habilidades={habilidades} />}
    </article>
  );
}
