export default function Idiomas({idiomas}) {
  const MostraIdiomas = ({ idiomas }) => {
    let idiomaLista = "";
    idiomas.forEach(
      (item, index) =>
        (idiomaLista += item + (idiomas.length > index + 1 ? ", " : ""))
    );

    return <p className="text">{idiomaLista}</p>;
  };
  return (
    <article className="section">
      <h4>Idiomas</h4>
      {!!idiomas.length && <MostraIdiomas idiomas={idiomas} />}
    </article>
  );
}
