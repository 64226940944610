import { PieChart } from "react-minimal-pie-chart";
import "./pie.css";

export default function Pie(props) {
  const color = !!props.color ? props.color : "#55ccff";
  const backgroundColor = "rgba(255,255,255,0.8)";
  const totalValue = 100;
  const animationDuration = !!props.animationDuration ? props.animationDuration : 2000
  const habilidade = props.habilidade;

  return (
    <div className="pie-container">
      <div className="pie-content">
        <div className="pie-chart-container">
          <PieChart
            animate={true}
            lineWidth={32}
            startAngle={270}
            totalValue={totalValue}
            paddingAngle={5}
            animationDuration={animationDuration}
            label={() => `${habilidade.proficiency} %`}
            labelPosition={0}
            labelStyle={{ fontSize: "20px", fill: color }}
            data={[
              { value: habilidade.proficiency, color: color },
              { value: totalValue - habilidade.proficiency, color: backgroundColor},
            ]}
          />
        </div>
        <div>
          <p className="pie-label">{habilidade.label}</p>
        </div>
      </div>
    </div>
  );
}
