export const contato = {
  nome: "Thiago Neves",
  funcao: "Desenvolvedor Mobile",
  email: "thiagomn03@gmail.com",
  linkedin: "thiago-neves-29779031",
  github: "thiagomneves",
  phone: "(61) 99973-6931",
  whatsapp: "(61) 99973-6931",
  location: "Asa Norte - Brasília/DF",
};

export const habChart = [
  {
    label: "React.js",
    proficiency: 60,
  },
  {
    label: "React Native",
    proficiency: 70,
  },
  {
    label: "Express.js",
    proficiency: 50,
  },
  {
    label: "Laravel",
    proficiency: 80,
  },
  {
    label: "Javascript",
    proficiency: 95,
  },
  {
    label: "Typescript",
    proficiency: 40,
  },
  {
    label: "CSS",
    proficiency: 90,
  },
  {
    label: "PHP",
    proficiency: 85,
  },
  {
    label: "SQL",
    proficiency: 85,
  },
  {
    label: "Java",
    proficiency: 50,
  },
  {
    label: "Inglês",
    proficiency: 60,
  },
]

export const habilidades = [
  "React",
  "React Native",
  "Laravel",
  "Node.js",
  "Express.js",
  "Javascript",
  "PHP",
  "Java",
  "AdvPL",
  "Linux (Ubuntu, Debian, OpenSuse)",
  "SASS",
  "JWT",
  "jQuery",
  "MySQL",
  "Postgres",
  "Sqlite",
  "MongoDB",
  "ShellScript",
  "NPM",
  "Gulp",
  "WorkControl",
  "Crystal Reports",
  "Strapi",
  "Service Now",
];

export const formacao = [
  {
    title:
      "Graduação em Sistemas da Informação Universidade Estácio de Sá - Petrópolis/RJ",
    date: "01/2004 - 06/2011",
  },
];

export const idiomas = [
  "Inglês Intermediário"
]

export const expProfissional = [
  {
    empresa: "Banco do Brasil S.A.",
    date: "03/2022 - atual, presencial",
    descricao: `<p class="text">
    Desenvolvimento mobile utilizando React Native e backend
    utilizando Quarkus/Java.
  </p>
  <p class="text">
    Utilizando conceitos de metodologias ágil SCRUM e ferramenta
    baseada no painel Kanban
  </p>`
  },
  {
    empresa: "Techcom Tecnologia e Informatica Eireli",
    date: "07/2021 a 03/2022, semipresencial",
    descricao: `<p class="text">
    Cargo: Analista de Suporte de TI (alocado no Laboratório
    Nacional de Computação Científica)
  </p>
  <p class="text">
    Atividades: Instalação, configuração e suporte de sistemas Linux
    e demais ferramentas utilizadas no LNCC.
  </p>
  <p class="text">
    Intermediação junto ao suporte internacional da Dell para
    atualização de sistemas OneFS de storages Isilon.
  </p>`
  },
  {
    empresa: "Aprendanet Informática",
    date: "04/2019 a 02/2020, semipresencial",
    descricao: `<p class="text">
    Atividades: Desenvolvimento backend utilizando Laravel/PHP e
    front end Html, SASS/CSS, Jquery/Js
  </p>
  <p class="text">
    Criação de painel administrativo que permitiu o gerenciamento
    de conteúdo pelos usuários e a utilização de legendas nos
    vídeos. Configuração de sistema Maple.
  </p>
  <p class="text">
    Configuração de servidor LAMP utilizando o serviço EC2 (Amazon
    AWS).
  </p>
  <p class="text">
    Configuração e utilização de outros serviços AWS Route53,
    bucket S3 e integrando com servidor no CBPF via phpssh.
  </p>`
  }
]

export const cursos = [
  {
    plataforma: "Alura",
    titulo: "Formação React Native",
  },
  {
    plataforma: "Alura",
    titulo: "Formação Javascript para Back-end",
    duracao: "76 horas",
    certificado: "Formação Javascript para Back-end.pdf"
  },
  {
    plataforma: "Alura",
    titulo: "Agilidade: promovendo a transformação ágil",
    duracao: "6 horas",
    certificado: "Agilidade promovendo a transformação ágil.pdf"
  },
  {
    plataforma: "Alura",
    titulo: "Java OO: Entendendo a Orientação a Objetos",
    duracao: "8 horas",
    certificado: "Java OO Entendendo a Orientação a Objetos.pdf",
  },
  {
    plataforma: "Alura",
    titulo: "Java Polimorfismo: entenda herança e interfaces",
    certificado: "Java Polimorfismo entenda herança e interfaces.pdf",
    duracao: "16 horas",
  },
  {
    plataforma: "Alura",
    titulo: "TDD e Java: testes automatizados com JUnit",
    certificado: "TDD e Java testes automatizados com JUnit.pdf",
    duracao: "8 horas",
  },
  {
    plataforma: "Udemy",
    // eslint-disable-next-line no-script-url
    titulo: "JavaScript: Understanding the Weird Parts",
    certificado: "JavaScript Understanding the Weird Parts.pdf",
    duracao: "11,5 horas",
  },
  {
    plataforma: "Udemy",
    titulo: "Curso Completo de JavaScript Moderno - ES6",
    duracao: "3,5 horas",
    certificado: "Curso Completo de JavaScript Moderno - ES6.pdf"
  },
  {
    plataforma: "Udemy",
    titulo: "Laravel 5.6 Completo - O mais poderoso Framework PHP",
    certificado: "Laravel 5.6 Completo - O mais poderoso Framework PHP.pdf",
    duracao: "28,5 horas",
  },
  {
    plataforma: "Upinside",
    titulo: "HTML5 do Jeito Certo",
  },
  {
    plataforma: "Upinside",
    titulo: "CSS3",
  },
  {
    plataforma: "Upinside",
    titulo: "PHP OO",
  },
  {
    plataforma: "Upinside",
    titulo: "WorkControl",
  },
  {
    plataforma: "RNP",
    titulo: "Administração de Sistemas Linux",
    duracao: "40 horas",
    certificado: "cdc-ead-1041-21.pdf"
  }
]

// const resumo = `Trabalho com programação desde 2008, desde 2015 como programador web, tanto de frontend como de backend.
// Atualmente atuo como desenvolvedor mobile no Banco do Brasil utilizando tecnologia React Native e metodologias ágeis`
