export default function Formacao({ formacao }) {
  const AdicionaFormacao = ({item}) => {
    return (
      <>
        <p className="text mb-0">{item.title}</p>
        {!!item.date && <p className="date">{item.date}</p>}
      </>
    );
  };
  return (
    <article className="section">
      <h4>Formação</h4>
      {!!formacao.length && formacao.map((item, index) => <AdicionaFormacao key={index} item={item}/>)}
    </article>
  );
}
