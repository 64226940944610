import "./sidebar.css";
import Contato from "./components/Contato";
import { contato, habChart } from "./dados";
import Pie from "./components/Pie";

export default function Sidebar() {
  return (
    <aside className="sidebar">
      <div className="sidebar-content">
        <header className="section">
          {Object.keys(contato).length && <Contato contato={contato} />}
        </header>

        <div className="chart-container">
          {habChart.map((item, key) => {
            return <Pie habilidade={item} key={key}/>
          })}
        </div>

      </div>
    </aside>
  );
}
