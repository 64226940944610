import Cursos from "./components/Cursos";
import ExpProfissional from "./components/ExpProfissional";
import Formacao from "./components/Formacao";
import Habilidades from "./components/Habilidades";
import Idiomas from "./components/Idiomas";
import { cursos, expProfissional, formacao, habilidades, idiomas } from "./dados";
import "./main.css";

export default function Main() {
  return (
    <main className="main">
      <div className="main-content">
        <article className="section">
          <h3>Objetivo: Desenvolvedor Mobile</h3>
        </article>
        {!!habilidades.length && <Habilidades habilidades={habilidades} />}
        {!!idiomas.length && <Idiomas idiomas={idiomas}/>}
        {!!expProfissional.length && <ExpProfissional exp={expProfissional} />}
        {!!formacao.length && <Formacao formacao={formacao} />}

        {!!cursos.length && <Cursos cursos={cursos} />}
      </div>
    </main>
  );
}
