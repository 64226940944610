import {FaLinkedin, FaEnvelope, FaPhone, FaWhatsapp, FaGithub} from 'react-icons/fa';
import {IoLocationSharp} from 'react-icons/io5';

export default function Contato({contato}) {

  const cleanNumber = number => {
    let cleanned = number.replace('(', '')
    cleanned = cleanned.replace(')', '')
    cleanned = cleanned.replace('-', '')
    cleanned = cleanned.replace(' ', '')
    return cleanned
  }

  return (
    <article className='contato'>
      {!!contato.nome && <h4>{contato.nome}</h4>}
      {!!contato.funcao && <p>{contato.funcao}</p>}
      <div className="contato-content">
        <div className="left">
          {!!contato.email && <a href={`mailto:${contato.email}`}><p><FaEnvelope/> {contato.email}</p></a>}
          {!!contato.linkedin && <a href={`https://www.linkedin.com/in/${contato.linkedin}`}><p><FaLinkedin/> {contato.linkedin}</p></a>}
          {!!contato.github && <a href={`https://github.com/${contato.github}`}><p><FaGithub />{contato.github}</p></a>}
        </div>
        <div className="right">
          {!!contato.phone && <a href={`tel:${contato.phone}`}><p><FaPhone/> {contato.phone}</p></a>}
          {!!contato.whatsapp && <a href={`https://wa.me/55${cleanNumber(contato.whatsapp)}`}><p><FaWhatsapp/> {contato.whatsapp}</p></a>}
          {!!contato.location && <p><IoLocationSharp/> {contato.location}</p>}
        </div>
      </div>
    </article>
  )
}
